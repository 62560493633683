import React from "react"

import { Flex, Heading, Stack, Box, Text } from "@chakra-ui/react"
import CategorySVGSwitch from "../../../config/svgs"

const FunnelHero = ({ kategorie, title, subtitle, jahr, type }) => {
  return (
    <Box
      as="section"
      mb={8}
      width="100vw"
      top={0}
      left="50%"
      right="50%"
      marginLeft="-50vw"
      marginRight="-50vw"
      position="relative"
    >
      <Stack maxW="7xl" w="100%" mx="auto" h="100%" position="relative" py={8}>
        <Flex
          mx="auto"
          position="relative"
          direction={{ base: "column", md: "row" }}
          align={{ md: "center" }}
          maxW="1100px"
          w="100%"
        >
          <Stack
            w="full"
            maxW={{ md: "xl" }}
            mb={{ base: "1rem", md: 16 }}
            mr={{ md: 12 }}
            mt={{ md: 8 }}
            px={4}
          >
            {jahr && <Text
              color="white"
              lineHeight="1.3125rem"
              fontFamily="CocoSharpL-Bold"
            >
              {jahr}
            </Text>}
            <Heading
              fontFamily="CocoSharp-Bold"
              color="white"
              fontSize="2.5rem"
              lineHeight="2.5625rem"
              letterSpacing="1px"
              mb={4}
            >
              {title}
            </Heading>
            <Text color="white" pr={{ md: 8 }} lineHeight="1.3125rem">
              {subtitle}
            </Text>
          </Stack>
          <Flex
            alignItems="center"
            maxW="300px"
            w="100%"
            ml="auto"
            mr={{ base: "auto", md: "10%" }}
            mt={{ base: 4, md: 0 }}
            px={{ base: 4, md: 0 }}
          >
            {CategorySVGSwitch(kategorie)}
          </Flex>
        </Flex>
      </Stack>

      <Box
        sx={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          bottom: 0,
          zIndex: -1,
          svg: {
            width: "100%",
            height: "100%",
          },
        }}
      >
        <Radial />
      </Box>
    </Box>
  )
}

export default FunnelHero

const Radial = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1080"
    height="295.262"
    viewBox="0 0 1080 295.262"
    preserveAspectRatio="none"
  >
    <defs>
      <radialGradient
        id="radial-gradient"
        cx="0.642"
        cy="0.751"
        r="0.872"
        gradientTransform="matrix(0.454, -0.891, 0.35, 0.178, 0.088, 1.189)"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#002149" />
        <stop offset="1" stopColor="#005a98" />
      </radialGradient>
    </defs>
    <path
      id="Path_2499"
      data-name="Path 2499"
      d="M0,0H1080V295.262H0Z"
      fill="url(#radial-gradient)"
    />
  </svg>
)
