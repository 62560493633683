import React from "react"
import { Link } from "gatsby"
import { SimpleGrid, Box, Heading, Text, Center } from "@chakra-ui/react"
import { colors } from "../../../config/categories"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const FunnelGrid = ({ data }) => {
  return (
    <Box as="section" pt={12}>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3 }}
        overflow="hidden"
        marginRight="-2px"
        gridGap="1px"
      >
        {data.map(item => {
          const { title, uri, featuredImage } = item.node
          const { teaserText, kategorie } =
            item.node.pagebuilder.fixeElementeTop

          const image = featuredImage && getImage(featuredImage.node.localFile)

          return (
            <Link to={uri} key={uri}>
              <Box
                outline="1px solid #002149"
                height="100%"
                pt={8}
                pb={4}
                px={{ md: 4 }}
                role="group"
                position="relative"
              >
                {/* <Flex direction="column" height="100%"> */}
                {/* {image && ( */}
                <Box
                  // height="100%"
                  overflow="hidden"
                  flex={1}
                  w="100%"
                  h={"300px"}
                  mb={8}
                  // maxH={index !== 0 && "250px"}
                >
                  {image && (
                    <GatsbyImage
                      image={image}
                      style={{
                        height: "100%",
                        overflow: "hidden",
                        width: "100%",
                      }}
                      imgStyle={{
                        overflow: "hidden",
                        height: "100%",
                      }}
                      alt={featuredImage?.node?.altText}
                    />
                  )}
                </Box>
                {/* )} */}
                <Heading
                  as="h3"
                  fontFamily="CocoSharp-Bold"
                  fontSize="1.375rem"
                  lineHeight="1.375rem"
                  color="dark_blue"
                  mt={2}
                  mb={4}
                >
                  {title}
                </Heading>
                <Box pb={4}>
                  <Text
                    overflow="hidden"
                    whiteSpace="normal"
                    color="dark_blue"
                    lineHeight="1.3125rem"
                    dangerouslySetInnerHTML={{
                      __html: teaserText,
                    }}
                  />
                </Box>
                {/* </Flex> */}
                {/* Hover Screen */}
                <Box
                  display="none"
                  _groupHover={{
                    display: "block",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    height: "100%",
                    width: "100%",
                    backgroundColor: kategorie
                      ? `${colors[kategorie[0]]}90`
                      : null || "rgba(205, 239, 205, 0.9)",
                    backdropFilter: "blur(20px)",
                  }}
                >
                  <Center flex={1} height="100%" flexDirection="column">
                    <Text
                      fontSize="xl"
                      textDecoration="underline"
                      textDecorationThickness="1px"
                      textDecorationColor="dark_blue"
                      mt={-3}
                      mb={4}
                      opacity={1}
                    >
                      Mehr erfahren
                    </Text>
                    <Arrow />
                  </Center>
                </Box>
              </Box>
            </Link>
          )
        })}
      </SimpleGrid>
    </Box>
  )
}

export default FunnelGrid

export const Arrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="39.709"
    height="42.779"
    viewBox="0 0 39.709 42.779"
  >
    <g id="Group_1605" data-name="Group 1605" transform="translate(0 0.707)">
      <g id="Group_330" data-name="Group 330" transform="translate(0)">
        <line
          id="Line_42"
          data-name="Line 42"
          y1="39"
          transform="translate(39 20.682) rotate(90)"
          fill="none"
          stroke="#002149"
          strokeWidth="2"
        />
        <line
          id="Line_41"
          data-name="Line 41"
          y1="29.25"
          transform="translate(18.318) rotate(-45)"
          fill="none"
          stroke="#002149"
          strokeWidth="2"
        />
        <line
          id="Line_45"
          data-name="Line 45"
          y1="29.25"
          transform="translate(39.002 20.682) rotate(45)"
          fill="none"
          stroke="#002149"
          strokeWidth="2"
        />
      </g>
    </g>
  </svg>
)
